<template>
    <v-container>
        <v-card justify="center" cols="12" md="6" lg="4" >
            <v-row justify="center">
                <v-col cols="6" md="3" lg="2"><router-link to="/calendarUser"><v-btn class="menu-btn" dark color="primary">Calendar</v-btn></router-link></v-col>
                <v-col cols="6" md="3" lg="2"><v-btn class="menu-btn" dark color="primary">Biking</v-btn></v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
export default {
    name: 'Menu',
    data () {
        return {
        }
    }
}

</script>

<style>
.menu-btn {
    width: 150px    
}
</style>